<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title color="white">Nuovo rapportino</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <!-- Customer list, triggered with click on select client in form -->
                <Transition name="fade-transition">
                    <div v-show="showCustomer" class="customer_modal">
                        <div class="customers_container">
                            <input type="text" placeholder="Cerca cliente" v-model="searchQuery" class="search_customers" />
                            <div class="customers_list">
                                <div
                                    v-for="customer in searchedCustomers"
                                    :key="customer.customers_id"
                                    @click="setSelectedCustomer(customer)"
                                    class="customer"
                                    :class="setActiveCustomer(customer, selectedCustomer)"
                                >
                                    {{ customer.customers_company ? customer.customers_company : `${customer.customers_name} ${customer.customers_last_name}` }}
                                </div>
                            </div>
                        </div>
                        <div @click="closeCustomerSelection()" class="close_modal">Chiudi</div>
                    </div>
                </Transition>

                <!-- Commesse list, triggered with click on select client in form -->
                <Transition name="fade-transition">
                    <div v-show="showCommesse" class="customer_modal">
                        <div class="customers_container">
                            <input type="text" placeholder="Cerca commessa" v-model="searchQueryCommessa" class="search_customers" />
                            <div class="customers_list">
                                <div
                                    v-for="commessa in searchedCommessa"
                                    :key="commessa.projects_id"
                                    @click="setSelectedCommessa(commessa)"
                                    class="customer"
                                    :class="setActiveCommessa(commessa, selectedCommessa)"
                                >
                                    {{ commessa.projects_name }}
                                </div>
                            </div>
                        </div>
                        <div @click="closeCommessaSelection()" class="close_modal">Chiudi</div>
                    </div>
                </Transition>

                <form @submit.prevent="creaRapportino()">
                    <ion-list class="fields">
                        <!-- Cliente -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title customer_selection" position="floating">
                                    <span>
                                        Cliente
                                    </span>
                                    <div @click="openCustomerSelection()" class="trigger">seleziona</div>
                                </div>
                                <div class="value">
                                    <div v-if="selectedCustomer">
                                        {{
                                            selectedCustomer.customers_company
                                                ? selectedCustomer.customers_company
                                                : `${selectedCustomer.customers_name} ${selectedCustomer.customers_last_name}`
                                        }}
                                    </div>
                                    <div v-else>Nessun cliente selezionato</div>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Commessa -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title customer_selection" position="floating">
                                    <span>
                                        Commessa
                                    </span>
                                    <div @click="openCommessaSelection()" class="trigger">seleziona</div>
                                </div>
                                <div class="value">
                                    <div v-if="selectedCommessa">
                                        {{ selectedCommessa.projects_name }}
                                    </div>
                                    <div v-else>Nessuna commessa selezionata</div>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Operatori -->
                        <ion-item v-if="richiediOperatori" lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Operatori
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-select multiple cancel-text="Annulla" ok-text="Conferma" v-model="rapportino.operatori">
                                        <ion-select-option v-for="(tecnico, index) in operatori" :key="index" :value="tecnico.users_id">
                                            {{ `${tecnico.users_last_name} ${tecnico.users_first_name}` }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Ora inizio -->
                        <div class="flex_container">
                            <div class="field">
                                <div class="field_title">
                                    Ora inizio
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="ora_inizio"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="rapportino.ora_inizio"
                                        placeholder="Seleziona ora di inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                            <div class="field">
                                <div class="field_title">
                                    Ora fine
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="ora_fine"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="rapportino.ora_fine"
                                        placeholder="Seleziona ora di inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </div>

                        <!-- Descrizione -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Note
                                </div>
                                <div class="value">
                                    <ion-textarea
                                        type="text"
                                        rows="4"
                                        v-model="rapportino.note"
                                        placeholder="Indicare qui eventuali servizi non svolti o motivare gli straordinari"
                                        class="custom_input"
                                    >
                                    </ion-textarea>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Checklist rapportino basata sul tipo commessa -->
                        <div v-if="sondaggio">
                            <div v-for="(step, i) in sondaggio" :key="i" class="single_step">
                                <div class="single_step_title">{{ step.step }}</div>
                                <div v-for="(domanda, j) in step.domande" :key="j" class="single_field">
                                    <div class="domanda_title">{{ domanda.sondaggi_domande_domanda }}</div>

                                    <!-- Input type (risposta breve) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta breve'">
                                        <ion-input type="text" v-model="formFields[domanda.sondaggi_domande_id]"> </ion-input>
                                    </div>

                                    <!-- Textarea (risposta paragrafo) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Paragrafo'">
                                        <ion-textarea v-model="formFields[domanda.sondaggi_domande_id]"> </ion-textarea>
                                    </div>

                                    <!-- Select (risposta singola) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta singola'">
                                        <ion-select
                                            interface="action-sheet"
                                            cancel-text="Annulla"
                                            ok-text="Conferma"
                                            v-model="formFields[domanda.sondaggi_domande_id]"
                                        >
                                            <ion-select-option
                                                v-for="(option, index) in domanda.risposte"
                                                :key="index"
                                                :value="option.sondaggi_domande_risposte_id"
                                            >
                                                {{ `${option.sondaggi_domande_risposte_risposta}` }}
                                            </ion-select-option>
                                        </ion-select>
                                    </div>

                                    <!-- Radio (risposta singola) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta singola - radio'">
                                        <ion-radio-group
                                            :value="domanda.risposte[0].sondaggi_domande_risposte_id"
                                            @ionChange="setRadioRisposta($event, domanda.sondaggi_domande_id)"
                                            v-model="formFields[domanda.sondaggi_domande_id]"
                                        >
                                            <ion-item
                                                v-for="(option, index) in domanda.risposte"
                                                :key="index"
                                                lines="none"
                                                class="ion-no-padding ion-no-margin"
                                                style="height: 40px;"
                                            >
                                                <ion-label class="ion-no-padding ion-no-margin">{{ `${option.sondaggi_domande_risposte_risposta}` }}</ion-label>
                                                <ion-radio
                                                    slot="start"
                                                    :value="option.sondaggi_domande_risposte_id"
                                                    class="ion-no-padding ion-no-margin ion-margin-end"
                                                ></ion-radio>
                                            </ion-item>
                                        </ion-radio-group>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Immagini rapportino -->
                        <ion-item lines="none" class="ion-no-padding" v-if="immaginiRapportino.length != 0">
                            <div class="field">
                                <div class="field_title" position="floating">Immagini</div>
                                <div class="foto_container">
                                    <div v-for="(foto, index) in immaginiRapportino" :key="index" class="single_foto">
                                        <ion-thumbnail>
                                            <!-- <img :src="setImageUrl(foto)" /> -->
                                            <img :src="`data:image/png;base64,${foto.data}`" />
                                        </ion-thumbnail>
                                        <!-- <div @click="deletePhoto(foto.name)" class="remove_photo">Rimuovi</div> -->
                                    </div>
                                </div>
                            </div>
                        </ion-item>

                        <div v-if="selectedCommessa" class="actions_firme">
                            <button
                                v-if="selectedCommessa.projects_richiesta_firma_cliente && selectedCommessa.projects_richiesta_firma_cliente == '1'"
                                type="button"
                                class="btn_firma"
                                :class="{ btn_signed: customerHasSigned }"
                                @click="openSignature('cliente')"
                            >
                                Firma cliente
                            </button>
                            <button
                                v-if="selectedCommessa.projects_richiesta_firma_operatore && selectedCommessa.projects_richiesta_firma_operatore == '1'"
                                type="button"
                                class="btn_firma"
                                :class="{ btn_signed: technicianHasSigned }"
                                @click="openSignature('tecnico')"
                            >
                                Firma operatore
                            </button>
                        </div>

                        <div class="action">
                            <!-- <div class="btn_allega_foto" @click="openModalPhotos()">Carica foto</div> -->
                            <button type="submit" class="btn_crea_intervento" :disabled="savingRapportino">
                                Salva rapportino
                            </button>
                        </div>
                    </ion-list>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    modalController,
    IonList,
    IonItem,
    IonDatetime,
    IonTextarea,
    IonText,
    IonThumbnail,
    IonIcon,
    IonInput,
    IonLabel,
    IonRadio,
    IonRadioGroup,
    IonSelect,
    IonSelectOption,
} from "@ionic/vue";
import { arrowBackOutline, close } from "ionicons/icons";

import { defineComponent, ref, reactive, computed, toRefs, onMounted } from "vue";

import moment from "moment";

import { openToast } from "@/services/toast";
import { dateFormat } from "@/services/utils";

import apiTecnici from "@/services/tecnici";
import apiPlan from "@/services/impostazioni_plan";
import apiClienti from "@/services/clienti";
import apiCommesse from "@/services/commesse";
import apiRapportini from "@/services/rapportini";
//import apiServiziCommessa from "@/custom/services/servizi_commessa";
import apiSondaggi from "@/services/sondaggi";

import ModalFirmaIntervento from "@/components/ModalFirmaIntervento";
import FotoRapportino from "@/components/rapportini/FotoRapportino";

export default defineComponent({
    name: "NuovoRapportino",
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonList,
        IonItem,
        IonDatetime,
        IonTextarea,
        IonText,
        IonThumbnail,
        IonIcon,
        IonInput,
        IonLabel,
        IonRadio,
        IonRadioGroup,
        IonSelect,
        IonSelectOption,
    },
    setup() {
        const userID = JSON.parse(localStorage.getItem("unserInfoEntasys")).dipendenti_user_id;
        const dipendenteID = JSON.parse(localStorage.getItem("unserInfoEntasys")).dipendenti_id;

        const today = moment().format("YYYY-MM-DD");
        const start_hour = moment()
            .subtract(2, "hours")
            .format("YYYY-MM-DD HH:mm");
        const end_hour = moment().format("YYYY-MM-DD HH:mm");

        const rapportino = reactive({
            cliente: null,
            appuntamento: null,
            commessa: null,
            data: moment().format("YYYY-MM-DD"),
            ora_inizio: moment(start_hour).toISOString(),
            ora_fine: moment(end_hour).toISOString(),
            operatori: [userID],
            note: null,
            immagini: [],
            firma_tecnico: "",
            firma_cliente: "",
        });

        const successResponse = ref(false);

        //Contenitore v-model del form
        const formFields = ref({});

        /********************************************
         *
         * ! IMPOSTAZIONI MODULO PLAN e OPERATORI
         *
         ********************************************/
        const richiediOperatori = ref(false);
        async function loadImpostazioni() {
            const res = await apiPlan.getImpostazioni();
            if (res.status === 200 && res.data.status === 0) {
                //settings.value = res.data.data;
                if (res.data.data.length != 0) {
                    richiediOperatori.value =
                        res.data.data[0].impostazioni_plan_richiedi_operatori && res.data.data[0].impostazioni_plan_richiedi_operatori == 1 ? true : false;
                }
            }
        }
        loadImpostazioni();

        const operatori = ref([]);
        async function loadTecnici() {
            const res = await apiTecnici.getTecnici();
            if (res.status === 200 && res.data.status === 0) {
                operatori.value = res.data.data;
            }
        }
        loadTecnici();

        /**
         * ! Close modal after form data submit
         */
        async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Open modal to sign
         */
        const technicianHasSigned = ref(false);
        const customerHasSigned = ref(false);

        async function openSignature(signatureType) {
            const modal = await modalController.create({
                component: ModalFirmaIntervento,
                componentProps: {
                    data: signatureType,
                    firma: signatureType === "tecnico" ? rapportino.firma_tecnico : rapportino.firma_cliente,
                },
            });
            modal.onDidDismiss().then((detail) => {
                //console.log(detail.data);
                if (detail.data) {
                    //Cliente e/o tecnico hanno firmato, cambia classe al button
                    if (detail.data.firma && detail.data.tipo) {
                        if (detail.data.tipo === "tecnico") {
                            rapportino.firma_tecnico = detail.data.firma;
                            if (detail.data.firma && detail.data.firma.length != 0) {
                                technicianHasSigned.value = true;
                            }
                        } else {
                            rapportino.firma_cliente = detail.data.firma;
                            if (detail.data.firma && detail.data.firma.length != 0) {
                                customerHasSigned.value = true;
                            }
                        }
                    }
                    //Firma cliente e/o tecnico è stata pulita e chiusa la modale, devo togliere la firma da rapportino
                    if (detail.data.da_cancellare === true && detail.data.tipo) {
                        if (detail.data.tipo === "tecnico") {
                            rapportino.firma_tecnico = "";
                            technicianHasSigned.value = false;
                        } else {
                            rapportino.firma_cliente = "";
                            customerHasSigned.value = false;
                        }
                    }
                }
            });
            return modal.present();
        }

        /*************************************************
         *
         * ! Ricerca e selezione commesse con modale
         *
         *************************************************/
        const showCommesse = ref(false);
        function openCommessaSelection() {
            showCommesse.value = true;
        }
        function closeCommessaSelection() {
            showCommesse.value = false;
        }

        /**
         * ! Load commesse by selected customer and by logged user in projects_members
         */
        const commesse = ref([]);
        async function loadCommesse(customer) {
            if (!customer) {
                openToast("Prima di selezionare la commessa devi scegliere il cliente", "toast_danger");
                return;
            }
            try {
                const res = await apiCommesse.getCommesseCliente(customer.customers_id);
                if (res.status === 0) {
                    commesse.value = res.data;
                } else {
                    openToast("Errore durante la richiesta delle commesse", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta delle commesse", "toast_danger");
            }
        }

        const searchQueryCommessa = ref("");
        const searchedCommessa = computed(() => {
            const term = searchQueryCommessa.value.replace(/ /g, "");
            return commesse.value.filter((commessa) => {
                if (commessa.projects_name) {
                    return (
                        commessa.projects_name
                            .toLowerCase()
                            .replace(/ /g, "")
                            .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                    );
                }
            });
        });

        const selectedCommessa = ref(null);
        //Ricerca sondaggio associato alla commessa selezioanta
        const sondaggio = ref(null);
        async function loadSondaggio(type) {
            try {
                const res = await apiSondaggi.getSondaggioCommessa(type);
                console.log(res);
                if (res.data.status === 1) {
                    sondaggio.value = res.data.data;
                } else {
                    //openToast(`${res.data.txt}`, "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta della checklist", "toast_danger");
            }
        }

        function setSelectedCommessa(commessa) {
            //console.log(commessa);
            if (commessa) {
                searchQueryCommessa.value = "";
                selectedCommessa.value = commessa;
                showCommesse.value = false;
                //imposta il progetto sul rapportino
                rapportino.commessa = commessa.projects_id;
                //Svuota eventuali firme effettuate prima del cambio commessa
                technicianHasSigned.value = false;
                customerHasSigned.value = false;
                rapportino.firma_tecnico = "";
                rapportino.firma_cliente = "";
                //Ricerca sondaggio per commessa selezionata
                loadSondaggio(selectedCommessa.value.projects_type);
            }
        }
        const setActiveCommessa = computed(() => {
            return (commessa, selectedCommessa) => {
                let className = "";
                if (selectedCommessa) {
                    if (commessa.projects_id === selectedCommessa.projects_id) {
                        className = "active_customer";
                    }
                }
                return className;
            };
        });

        /*******************************************
         *
         * ! Ricerca e selezione cliente con modale
         *
         ********************************************/
        const showCustomer = ref(false);
        function openCustomerSelection() {
            showCustomer.value = true;
        }
        function closeCustomerSelection() {
            showCustomer.value = false;
        }

        /**
         * !Load customer
         */
        const customers = ref([]);
        async function loadCustomer() {
            try {
                const res = await apiClienti.getCustomers();
                if (res.status === 0) {
                    customers.value = res.data;
                } else {
                    openToast("Errore durante la richiesta dei clienti", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei clienti", "toast_danger");
            }
        }

        const searchQuery = ref("");
        const searchedCustomers = computed(() => {
            const term = searchQuery.value.replace(/ /g, "");
            return customers.value.filter((cliente) => {
                //company, nome/cognome
                if (cliente.customers_company) {
                    return (
                        cliente.customers_company
                            .toLowerCase()
                            .replace(/ /g, "")
                            .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                    );
                } else {
                    if (cliente.customers_name && cliente.customers_last_name) {
                        return (
                            cliente.customers_name
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                            cliente.customers_last_name
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                            (cliente.customers_last_name + cliente.customers_name)
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                            (cliente.customers_name + cliente.customers_last_name)
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                        );
                    } else if (cliente.customers_name && !cliente.customers_last_name) {
                        return (
                            cliente.customers_name
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                        );
                    } else if (!cliente.customers_name && cliente.customers_last_name) {
                        return (
                            cliente.customers_last_name
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                        );
                    }
                }
            });
        });

        const selectedCustomer = ref(null);
        function setSelectedCustomer(customer) {
            //console.log(customer);
            if (customer) {
                searchQuery.value = "";
                selectedCustomer.value = customer;
                showCustomer.value = false;
                rapportino.cliente = customer.customers_id;
                //Svuoto eventuale selezione precedente della commessa
                rapportino.commessa = null;
                selectedCommessa.value = null;
                //Prendo commesse del cliente selezionato
                loadCommesse(customer);
            }
        }
        const setActiveCustomer = computed(() => {
            return (customer, selectedCustomer) => {
                let className = "";
                if (selectedCustomer) {
                    if (customer.customers_id === selectedCustomer.customers_id) {
                        className = "active_customer";
                    }
                }
                return className;
            };
        });

        /**
         * ! Gestione foto rapportino
         */
        const immaginiRapportino = ref([]);
        async function openModalPhotos() {
            const modal = await modalController.create({
                component: FotoRapportino,
                /* componentProps: {
                    images: immaginiRapportino.value,
                }, */
            });
            modal.onDidDismiss().then((detail) => {
                //console.log(detail);
                if (detail.data != undefined) {
                    const foto = detail.data;
                    if (foto) {
                        foto.forEach((singleFoto) => {
                            //console.log(singleFoto);
                            immaginiRapportino.value.push(singleFoto);
                        });
                    }
                }
            });
            return modal.present();
        }

        /**
         * ! Remove photo from the fotoInterventi reference data array
         */
        function deletePhoto(photo) {
            rapportino.immagini = rapportino.immagini.filter((immagine) => immagine.name !== photo);
        }

        function setImageUrl(path) {
            return `${process.env.VUE_APP_BASE_URL}/uploads/${path}`;
        }

        /****************************************************************
         *
         * ! Set richiesta.usa_banca_ore value based on selected radio
         *
         *****************************************************************/
        function setRadioRisposta(event, reference) {
            const value = event.target.value;
            formFields.value[reference] = value;
        }

        /**
         * ! Crea sondaggio legato da associare al rapporitno
         */
        async function createSondaggio() {
            const response = {
                data: null,
                success: false,
            };

            if (sondaggio.value && Object.keys(formFields.value).length === 0) {
                openToast("Non è stata fornita alcuna risposta alla checklist", "toast_danger");
                return response;
            }

            //Se ho un sondaggio provo a crearlo
            if (sondaggio.value) {
                // Prendo le referenze alle proprietà dell'oggetto reattivo
                const refs = toRefs(formFields.value);
                //Mi costruisco muovo oggetto nell formato corretto
                const formattedObject = {};
                for (const key in refs) {
                    formattedObject[`risposta[${key}]`] = refs[key].value;
                }

                //Mi creo l'oggetto FormData
                const formData = new FormData();
                for (const key in formattedObject) {
                    formData.append(key, formattedObject[key]);
                }
                formData.append("user_id", userID);
                formData.append("sondaggio_id", sondaggio.value[0].sondaggio_id);
                formData.append("sondaggio_da_app", 1);

                try {
                    const res = await apiSondaggi.saveCompilazione(formData);

                    if (res.data.status == 0) {
                        openToast(`${res.data.txt}`, "toast_danger");
                        response.success = false;
                        return response;
                    } else if (res.data.status == 1) {
                        response.data = res.data;
                        response.success = true;
                        return response;
                    }
                } catch (error) {
                    console.error(error);
                    openToast("Errore durante il salvataggio della checklist", "toast_danger");
                    return response;
                }
            }
        }

        /**
         * ! Create new rapportino
         */
        const savingRapportino = ref(false);

        async function creaRapportino() {
            savingRapportino.value = true;

            //Controllo che ci sia data
            if (!rapportino.data) {
                savingRapportino.value = false;
                openToast("Non puoi creare il rapportino senza selezionare la data", "toast_danger");
                return;
            }
            //Controllo che ci sia cliente
            if (!rapportino.cliente) {
                savingRapportino.value = false;
                openToast("Non puoi creare il rapportino senza selezionare il cliente", "toast_danger");
                return;
            }
            //Controllo che ci sia commessa
            if (!rapportino.commessa) {
                savingRapportino.value = false;
                openToast("Non puoi creare il rapportino senza selezionare la commessa", "toast_danger");
                return;
            }
            //Controllo che ci sia almeno un operatore selezioanto
            if (rapportino.operatori.length === 0) {
                savingRapportino.value = false;
                openToast("Non puoi creare il rapportino senza selezionare gli operatori", "toast_danger");
                return;
            }
            //Controllo di essere sempre tra gli operatori selezionati
            const isUSerSelected = rapportino.operatori.includes(userID);
            if (!isUSerSelected && rapportino.operatori.length != 0) {
                savingRapportino.value = false;
                openToast("Non puoi creare il rapportino senza essere tra gli operatori selezionati", "toast_danger");
                return;
            }
            //Controllo che ora inizio non sia successiva ad ora fine
            const ora_inizio = new Date(rapportino.ora_inizio);
            const ora_fine = new Date(rapportino.ora_fine);
            if (ora_inizio > ora_fine) {
                savingRapportino.value = false;
                openToast("L'ora di inizio non può essere maggiore dell'ora di fine", "toast_danger");
                return;
            }

            const ora_inizio_formatted = moment(rapportino.ora_inizio).format("HH:mm");
            const ora_fine_formatted = moment(rapportino.ora_fine).format("HH:mm");

            const data = new FormData();
            data.append("rapportini_cliente", rapportino.cliente);
            data.append("rapportini_commessa", rapportino.commessa);
            //data.append("rapportini_appuntamento_id", null;
            data.append("rapportini_data", rapportino.data);
            data.append("rapportini_ora_inizio", ora_inizio_formatted);
            data.append("rapportini_ora_fine", ora_fine_formatted);
            rapportino.operatori.forEach((tecnico) => {
                data.append("rapportini_operatori[]", tecnico);
            });

            if (rapportino.note) {
                data.append("rapportini_note", rapportino.note);
            }

            /*if (rapportino.immagini.length != 0) {
                rapportino.immagini.forEach((foto) => {
                    data.append("rapportini_immagini[]", foto.data);
                });
            }*/

            if (rapportino.firma_tecnico) {
                data.append("rapportini_firma_operatore", rapportino.firma_tecnico);
            }
            if (rapportino.firma_cliente) {
                data.append("rapportini_firma_cliente", rapportino.firma_cliente);
            }
            /*for (const value of data.entries()) {
                console.log(value[0] + ", " + value[1]);
            }
            return; */

            //Se ho un sondaggio associato
            if (sondaggio.value) {
                /**
                 * ? Creo la compilazione e poi associo l'id della compilazione al rapportino
                 */
                const sondaggioCreato = await createSondaggio();

                //Creazione sondaggio fallita, blocco esecuzione
                if (!sondaggioCreato.data || !sondaggioCreato.success) {
                    return;
                }

                //Creazione sondaggio OK, imposto dati compilazione per creazione rapportino
                if (sondaggioCreato.data && sondaggioCreato.success) {
                    const compilazione_id = sondaggioCreato.data.compilazione_id;
                    data.append("rapportini_compilazione_id", compilazione_id);

                    //Dopo creato l'intervento, prima di chiudere la modale inserisco le foto nella relazione
                    try {
                        const response = await apiRapportini.saveRapportino(data);

                        if (response.data.status === 8) {
                            openToast(response.data.message, "toast_danger");
                        } else {
                            const res = response.data.data[0];
                            //Inserisco le immagini nella relazione
                            if (immaginiRapportino.value.length != 0) {
                                const images = immaginiRapportino.value;
                                const result = await apiRapportini.salvaFotoRapportino(images, res.rapportini_id);
                            }
                            //Svuoto array immagini
                            immaginiRapportino.value = [];

                            successResponse.value = true;
                            closeModalOnSubmit(successResponse, res);
                        }
                    } catch (error) {
                        console.error(error);
                        savingRapportino.value = false;
                        openToast("Errore durante la creazione del rapportino", "toast_danger");
                    } finally {
                        setTimeout(() => {
                            savingRapportino.value = false;
                        }, 1000);
                    }
                }
            } else {
                // Non ho sondaggio, creo rapportino direttamente
                try {
                    const response = await apiRapportini.saveRapportino(data);

                    if (response.data.status === 8) {
                        openToast(response.data.message, "toast_danger");
                    } else {
                        const res = response.data.data[0];
                        //Inserisco le immagini nella relazione
                        if (immaginiRapportino.value.length != 0) {
                            const images = immaginiRapportino.value;
                            const result = await apiRapportini.salvaFotoRapportino(images, res.rapportini_id);
                        }
                        //Svuoto array immagini
                        immaginiRapportino.value = [];

                        successResponse.value = true;
                        closeModalOnSubmit(successResponse, res);
                    }
                } catch (error) {
                    console.error(error);
                    savingRapportino.value = false;
                    openToast("Errore durante la creazione del rapportino", "toast_danger");
                } finally {
                    savingRapportino.value = false;
                }
            }
        }

        onMounted(() => {
            loadCustomer();
        });

        return {
            closeModal,
            arrowBackOutline,
            close,
            creaRapportino,
            savingRapportino,
            rapportino,
            // Clienti
            customers,
            showCustomer,
            openCustomerSelection,
            closeCustomerSelection,
            searchQuery,
            searchedCustomers,
            setSelectedCustomer,
            selectedCustomer,
            setActiveCustomer,
            // Commesse
            commesse,
            showCommesse,
            openCommessaSelection,
            closeCommessaSelection,
            searchQueryCommessa,
            searchedCommessa,
            setSelectedCommessa,
            selectedCommessa,
            setActiveCommessa,
            dateFormat,
            // Gestione immagini
            openModalPhotos,
            deletePhoto,
            // Firme
            openSignature,
            technicianHasSigned,
            customerHasSigned,
            // Immagini
            immaginiRapportino,
            setImageUrl,
            // Sondaggio
            formFields,
            sondaggio,
            setRadioRisposta,
            // Flag richiesta operatori multiselect
            richiediOperatori,
            operatori,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    background: #f8fafc;
    padding: 16px;
}

.fields {
    width: 100%;
    border-radius: 4px;
    /*padding: 10px;*/
    padding: 16px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 6px;
}

.field_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #6b7280;
}

.field_title.customer_selection {
    display: flex;
    justify-content: space-between;
}

.field_title.customer_selection .trigger {
    font-size: 10px;
    padding: 4px 8px;
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    text-transform: capitalize;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}

ion-datetime,
ion-select {
    max-width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    --padding-start: 8px;
}

ion-input,
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    margin-top: 0;
    border: 1px solid #d1d5db;
    border-radius: 4px;
}
ion-button {
    --color: #ffffff;
}
.custom_input textarea::placeholder {
    font-size: 5px;
}

.btn_crea_intervento {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: #086fa3;
    color: #ffffff;
}
.btn_crea_intervento:disabled {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: rgb(62, 160, 208);
    color: #ffffff;
}

/** Allegati */
.btn_allega_foto {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    color: #ffffff;
    background-color: #06b6d4;
    margin: 24px 0;
}

.foto_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    margin-top: 8px;
}
.single_foto {
    margin-right: 16px;
    margin-bottom: 16px;
}
ion-thumbnail {
    --size: 120px;
    --border-radius: 4px;
}

.remove_photo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    border-radius: 4px;
    padding: 4px;
    font-size: 12px;
    font-weight: bold;
}

/** Customer selection  */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.customer_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    margin-top: 16px;
}
.search_customers {
    width: 100%;
    border: 1px solid #c3c8cf;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;
}
.search_customers::placeholder {
    color: #6b7280;
}
.search_customers:focus-visible {
    /* outline: 1px solid #509843;*/
    outline: 1px solid #334155;
}
.customers_container {
    width: 100%;
    height: 80%;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
    padding: 12px;
    display: flex;
    flex-direction: column;
}
.customers_list {
    overflow-y: scroll;
}

.customer {
    padding: 6px;
}
.active_customer {
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    font-weight: 500;
}

.flex_container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}
.flex_container .field {
    width: 47%;
}

.info_aggiuntive {
    font-size: 14px;
}

.actions_firme {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
}
.btn_firma {
    width: 47%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
}

.btn_firma.btn_signed {
    background-color: #086fa3;
    color: #ffffff;
    transition: all 0.15s ease-in;
}

/**
* Step domande
 */
.single_step {
    margin-bottom: 32px;
}
.single_step .single_step_title {
    font-weight: bold;
    margin-bottom: 8px;
}
.single_step .single_field {
    margin-bottom: 16px;
}
.single_step .single_field .domanda_title {
    margin-bottom: 4px;
}
</style>
